<template>
    <div>
        <el-form ref="queryForm" class="query-box" v-model="queryParams" :inline="true">
            <el-form-item label="机构/一代编号" prop="agentNo" label-width="140px">
                <el-input v-model="queryParams.agentNo"
                          clearable
                          placeholder="请输入机构编号"
                          style="width: 240px"
                          size="small" @keyup.enter.native="handleQuery" @blur="replaceComma"/>
            </el-form-item>
            <el-form-item label="机构/代理名称" prop="name" label-width="140px">
                <el-input v-model="queryParams.userName"
                          clearable
                          placeholder="请输入机构名称"
                          style="width: 240px"
                          size="small" @keyup.enter.native="handleQuery"/>
            </el-form-item>
            <el-form-item label="登录手机号" prop="name" label-width="140px">
                <el-input v-model="queryParams.contactMobileNo"
                          clearable
                          placeholder="请输入联系人手机号"
                          style="width: 240px"
                          size="small" @keyup.enter.native="handleQuery"/>
            </el-form-item>
            <el-form-item label="新增时间" label-width="140px">
                <el-date-picker v-model="createDateRange"
                                size="small" value-format="yyyy-MM-dd"
                                type="daterange" range-separator="-"
                                style="width: 240px"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"/>
            </el-form-item>
            <el-form-item label="审核通过时间" label-width="140px">
                <el-date-picker v-model="checkPassTime"
                                size="small" value-format="yyyy-MM-dd"
                                type="daterange" range-separator="-"
                                style="width: 240px"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"/>
            </el-form-item>
            <el-form-item label="结算状态" prop="name" label-width="140px">
                <el-select v-model="queryParams.setterStatus" placeholder="请选择结算状态" style="width: 240px">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="正常" value="1"></el-option>
                    <el-option label="冻结" value="2"></el-option>
                    <el-option label="分润冻结" value="4"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="分润代付开关" prop="name" label-width="140px">
                <el-select v-model="queryParams.profitSwitch" placeholder="请选择分润代付开关" style="width: 240px">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="开启" value="1"></el-option>
                    <el-option label="关闭" value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="返现代付开关" prop="name" label-width="140px">
                <el-select v-model="queryParams.cashbackSwitch" placeholder="请选择返现代付开关" style="width: 240px">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="开启" value="1"></el-option>
                    <el-option label="关闭" value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="审核状态" prop="name" label-width="140px">
                <el-select v-model="queryParams.status" placeholder="请选择审核状态" style="width: 240px">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="已创建" value="0"></el-option>
                    <el-option label="审核失败" value="1"></el-option>
                    <el-option label="待审核" value="2"></el-option>
                    <el-option label="审核终止" value="3"></el-option>
                    <el-option label="正常" value="4"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="类型" prop="name" label-width="140px">
                <el-select v-model="queryParams.userType" placeholder="请选择类型" style="width: 240px">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="企业" value="0"></el-option>
                    <el-option label="个人" value="1"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" icon="el-icon-search" size="mini"
                           @click="handleQuery" v-permission="'AGENT_INFO_QUERY'">
                    查询
                </el-button>
                <el-button type="primary" icon="el-icon-plus" size="mini"
                           @click="handleAdd"
                           v-permission="'AGENT_INFO_INSERT'">
                    新增一代
                </el-button>
            </el-col>
        </el-row>
        <el-dialog 
            :visible.sync="changeRole"
            width="400px">
            <div style="padding:10px 0 10px 20px"><el-radio v-model="userType" label="0">企业</el-radio></div>
            <div style="padding:10px 0 40px 20px"><el-radio v-model="userType" label="1">个人</el-radio></div>
            <el-row :gutter="10" class="mb8">
                <el-col :span="1.5">
                    <el-button type="primary"  @click="addAgent" >
                        新增
                    </el-button>
                    <el-button type="primary" @click="changeRole = false">
                        取消
                    </el-button>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                queryParams: {},
                createDateRange: [],
                checkPassTime: [],
                changeRole: false,
                showCustomForm: false,
                userType:'1'
            }
        },
        mounted() {
        },
        methods: {
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.commitChange();
            },
            handleQuery() {
                this.commitChange();
            },
            handleResetQuery(){
                this.queryParams = {};
                this.createDateRange = [];
                this.checkPassTime = [];
                this.commitChange();
            },
            handleAdd(){
                this.changeRole = true
            },
            addAgent(){
                this.changeRole = false
                this.$router.push({
                    name:'orgInsert',
                    query:{
                     userType: this.userType   
                    }
                })
            },
            dealWithQueryParams() {
                let [startCreateTime, endCreateTime] = this.createDateRange;
                this.queryParams.startCreateTime = startCreateTime && (startCreateTime + ' 00:00:00');
                this.queryParams.endCreateTime = endCreateTime && (endCreateTime + ' 23:59:59');

                let [startCheckPassTime, endCheckPassTime] = this.checkPassTime;
                this.queryParams.startCheckPassTime = startCheckPassTime && (startCheckPassTime + ' 00:00:00');
                this.queryParams.endCheckPassTime = endCheckPassTime && (endCheckPassTime + ' 23:59:59');
            },
            commitChange() {
                this.dealWithQueryParams();
                let params = {...this.queryParams};
                this.$emit('on-change', params);
            },
        }
    }
</script>

<style scoped>

</style>
